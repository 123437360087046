// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dark Theme CSS */

body {
    background-color: #1a1a1a;
    color: #fff;
  }
  
  h2 {
    color: #00bcd4;
  }
  
  p {
    color: #ccc;
  }
  
  .carousel-item {
    background-color: #333;
    border: 1px solid #555;
  }
  
  .carousel-item a {
    color: #00bcd4;
  }
  
  /* Add more styles as needed for your specific components */
  `, "",{"version":3,"sources":["webpack://./src/components/Leaderboard.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;IACI,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,sBAAsB;IACtB,sBAAsB;EACxB;;EAEA;IACE,cAAc;EAChB;;EAEA,2DAA2D","sourcesContent":["/* Dark Theme CSS */\r\n\r\nbody {\r\n    background-color: #1a1a1a;\r\n    color: #fff;\r\n  }\r\n  \r\n  h2 {\r\n    color: #00bcd4;\r\n  }\r\n  \r\n  p {\r\n    color: #ccc;\r\n  }\r\n  \r\n  .carousel-item {\r\n    background-color: #333;\r\n    border: 1px solid #555;\r\n  }\r\n  \r\n  .carousel-item a {\r\n    color: #00bcd4;\r\n  }\r\n  \r\n  /* Add more styles as needed for your specific components */\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
