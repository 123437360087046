import React, { useState, useEffect } from 'react';
import './Leaderboard.css';

const Leaderboard = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [nfts, setNfts] = useState([]);
  const opensea_key = process.env.REACT_APP_API;

  useEffect(() => {
    const fetchData = async () => {
        const ownersAndSocials = [
          { owner: 'DanIsNearby', social: 'DanIsNearby', displayName: 'DanIsNearby' },
          { owner: 'Passtor', social: 'chory_passtor', displayName: 'Passtor' },
          { owner: 'Strz4la', social: 'strz4lkowic', displayName: 'Strz4la' },
          { owner: 'Gambler1', social: 'meta_gambler', displayName: 'Gambler' },
          { owner: 'Gambler2', social: 'meta_gambler', displayName: 'Gambler' },
          { owner: 'Chadaa', social: 'stefankowal3', displayName: 'Chadaa' },
          { owner: 'Bart1', social: 'BartCryptoNFTs', displayName: 'Bart'},
          { owner: 'Bart2', social: 'BartCryptoNFTs', displayName: 'Bart'},
          { owner: 'Zencooler', social: 'Zencooler0', displayName: 'Zencooler'},
          { owner: 'Kabat', social: 'kabaciarz', displayName: 'Kabat'},
          { owner: 'Piotrek', social: 'piotr39236892', displayName: 'Piotrek'},
          { owner: 'Mic', social: '_michal_banas', displayName: 'Mic'},
          { owner: 'Norbert', social: 'BrzydkiNiczymB', displayName: 'Norbert'},
        ];
  
        const promises = ownersAndSocials.map(({ owner, social }) => {
          const address = {
            DanIsNearby: '0xD569c72387a1AAb1c8346C52acc00955326f4950',
            Passtor: '0x4b0F5858de81AA1aC27c47F9295E1dd635903E6E',
            Strz4la: '0x2BB0131F561Df1A69B49Da3d26C8501710327dc1',
            Gambler1: '0x98B71F1c4469DCb5D4519CA1EA253c5220b48c11',
            Gambler2: '0x9C11ed9d7c56ae34E9633EC612c8aACf7Bce7B96',
            Chadaa: '0x07ECF652956E4f629dfcC7AE1dB0Cc8a23Ac969e',
            Bart1: '0x02B41F76417bb46A36A2a788956E5e00adeDA365',
            Bart2: '0xD8D64A36eEC63105a67CEc56A44fDEBe9C6D128D',
            Zencooler: '0xE4FA2C869eA22cbDED5e742ad912200293bd3184',
            Kabat: '0xAcE732F4F8005263DB18917BC4D4F57b63B4aef0',
            Piotrek: '0x5f41F589Bb0F4D65ADaB0FA3aD974EF568585Fad',
            Mic: '0x7A26d77375D2E61c78cd70f9BBE50D7df72EBBed',
            Norbert: '0x35AF086499fedA7cb9d6E39695A2a56f741BaAb1',
          }[owner];
  
          if (!address) {
            return Promise.resolve({ nfts: [] });
          }
  
          return fetch(
            `https://api.opensea.io/api/v2/chain/ethereum/account/${address}/nfts?collection=michelin3xplorerclub`,
            {
              method: 'GET',
              headers: {
                accept: 'application/json',
                'x-api-key': `${opensea_key}`, 
              },
            }
          ).then((response) => response.json());
        });
  
        const results = await Promise.all(promises);
  
        const combinedNfts = results.flatMap((result, index) => {
          const { owner, social, displayName } = ownersAndSocials[index];
          return result.nfts.map((nft) => ({ ...nft, owner: displayName, social }));
        });
  
        setNfts(combinedNfts);
      };

    fetchData();
  }, []);

  return (
    <div>
      <h2>Leaderboard:</h2>
      {leaderboardData.map((item, index) => (
        <p key={index}>
          #{index + 1} {item.owner} - {item.numNFTs} NFTs
        </p>
      ))}
    </div>
  );
};

export default Leaderboard;
