import React from 'react';
import './Footer.css';

const Footer = ({ componentName }) => {
  return (
    <div className="footerBox">
      <div>
        <a href={`https://x.com/decentralinacja`} target="_blank" rel="noopener noreferrer">
          Powered by <span className="decentrlinacja">decentrlinacja</span>
        </a>
      </div>
      <div className="socialIcons">
        <a href="https://t.me/michelinpoland" target="_blank" rel="noopener noreferrer">
          <img src="/polishTelegram.png" alt="Twitter" />
        </a>
        <a href="https://t.me/decentralination" target="_blank" rel="noopener noreferrer">
          <img src="/telegram.png" alt="Twitter" />
        </a>
        <a href="https://x.com/michelinpoland" target="_blank" rel="noopener noreferrer">
          <img src="/x.png" alt="Telegram" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
