import React, { useState, useEffect } from 'react';
import Michelins from './components/Michelins';
import Footer from './components/Footer';
import Leaderboard from './components/Leaderboard';

const App = () => {
  return (
    <div>
      <Michelins />
      <Footer />
    </div>
  );
};

export default App;
