// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Style for "Powered by decentrlinacja" component */

.footerBox {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #888; /* Adjust the color as needed */
    margin-bottom: 40px;
  }
  
  .decentrlinacja {
    color: #61dafb; /* Color for the decentrlinacja text */
    font-weight: bold;
  }
  .socialIcons img{
    height: 40px;
    width: 40px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA,oDAAoD;;AAEpD;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,WAAW,EAAE,+BAA+B;IAC5C,mBAAmB;EACrB;;EAEA;IACE,cAAc,EAAE,sCAAsC;IACtD,iBAAiB;EACnB;EACA;IACE,YAAY;IACZ,WAAW;EACb","sourcesContent":["/* Style for \"Powered by decentrlinacja\" component */\r\n\r\n.footerBox {\r\n    text-align: center;\r\n    margin-top: 20px;\r\n    font-size: 14px;\r\n    color: #888; /* Adjust the color as needed */\r\n    margin-bottom: 40px;\r\n  }\r\n  \r\n  .decentrlinacja {\r\n    color: #61dafb; /* Color for the decentrlinacja text */\r\n    font-weight: bold;\r\n  }\r\n  .socialIcons img{\r\n    height: 40px;\r\n    width: 40px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
